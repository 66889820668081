export const BASE_URL = process.env.NODE_ENV === 'development'
  ? process.env.VUE_APP_DEV_API
  : process.env.VUE_APP_PROD_API

export const LOGIN = '/auth/accounts/login'

export const TRANSACTIONS = '/transactions'

export const LIEN = '/transactions/lien'

export const DASHBOARD = '/auth/admin/dashboard'

export const MANAGERS = '/auth/managers'

export const ONE_MANAGER = '/auth/manager'

export const USERS = '/auth/users'

export const PLANS = '/auth/recharge/data-plan'

export const CONVERSIONS = '/auth/networks/get-converted-airtime'

export const APPROVE_CONVERSION = '/auth/networks/update-status'

export const NOTIFICATIONS = '/notifications'

export const SEND_EMAIL = '/notifications/send-mail'

export const NETWORKS = '/auth/networks'

export const USER_TRANSACTIONS = '/transactions/user-transactions'

export const USER_DELETE = '/auth/users/delete'

export const USER_SUSPEND = '/auth/users/suspend'

export const USER_UNSUSPEND = '/auth/accounts/update-restriction'

export const USER_UNDELETE = '/auth/users/undelete'

export const EDIT_WALLET = '/auth/wallet/edit-user-wallet'

export const EDIT_LIEN = '/auth/wallet/edit-user-lien'

export const GET_FILE = '/file'
