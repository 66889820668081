import Vue from 'vue'
import { format } from 'date-fns'

Vue.filter('format-currency', (val) => {
  if (val) return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('format-date', (val) => {
  if (val) return format(new Date(val), 'dd/MM/yyyy')
})

Vue.filter('capitalize', (phrase) => {
  if (!phrase) {
    return ''
  }
  return phrase
    .toLowerCase().split('-')
    .map(word => word.charAt(0)
      .toUpperCase() + word.slice(1))
    .join(' ')
})
