import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import axios from 'axios'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    token: '',
    user: {},
    processing: false
  },
  getters: {
    isAuthenticated: (state) => state.token !== '',
    user: (state) => state.user,
    processing: (state) => state.processing
  },
  mutations: {
    setToken (state, payload) {
      axios.defaults.headers.common.Authorization = `Bearer ${payload}`
      state.token = payload
    },
    setUserData (state, payload) {
      state.user = payload
    },
    setProcessing (state, payload) {
      state.processing = payload
    }
  },
  actions: {
  },
  plugins: [vuexLocal.plugin]
})
