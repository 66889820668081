import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/users',
    name: 'Users',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "transactions" */ '../views/Transactions.vue')
  },
  {
    path: '/liens',
    name: 'Lien Log',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "transactions" */ '../views/Liens.vue')
  },
  {
    path: '/conversions',
    name: 'Conversions',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "conversions" */ '../views/Conversions.vue')
  },
  {
    path: '/notification',
    name: 'Notification',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "notification" */ '../views/Notification.vue')
  },
  {
    path: '/network',
    name: 'Network',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "network" */ '../views/Network.vue')
  },
  {
    path: '/validation',
    name: 'Verification',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "validation" */ '../views/Validation.vue')
  },
  {
    path: '/suspension',
    name: 'Suspension',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "suspension" */ '../views/Suspension.vue')
  },
  {
    path: '/profile/:id',
    name: 'Profile',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: '/history',
    name: 'History',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "history" */ '../views/History.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "home" */ '../views/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'auth', requiresAuth: false },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/change-rates',
    name: 'EditRates',
    meta: { layout: 'dashboard', requiresAuth: true },
    component: () => import(/* webpackChunkName: "EditRates" */ '../views/EditRates.vue')
  },
  {
    path: '*',
    meta: { layout: 'dashboard', requiresAuth: false },
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const authenticated = store.getters.isAuthenticated

  if (requiresAuth && !authenticated) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }

  if (to.fullPath === '/logout') {
    store.commit('setToken', '')
    store.commit('setUserData', {})
    return next('/login')
  }

  return next()
})

export default router
