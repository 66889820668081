export default [
  {
    header: true,
    title: '',
    hiddenOnCollapse: true
  },
  {
    href: '/',
    title: 'Dashboard',
    icon: 'icon-grid'
  },
  {
    href: '/users',
    title: 'Users',
    icon: 'icon-user'
  },
  {
    href: '/transactions',
    title: 'Transactions',
    icon: 'icon-directions'
  },
  {
    href: '/liens',
    title: 'Lien Log',
    icon: 'icon-directions'
  },
  {
    href: '/conversions',
    title: 'Conversions',
    icon: 'icon-refresh'
  },
  {
    href: '/network',
    title: 'Network',
    icon: 'icon-globe'
  },
  // {
  //   href: '/history',
  //   title: 'History',
  //   icon: 'icon-compass'
  // },
  {
    href: '/notification',
    title: 'Notification',
    icon: 'icon-bell'
  },
  {
    href: '/validation',
    title: 'Validation',
    icon: 'icon-shield'
  },
  {
    href: '/suspension',
    title: 'Suspension',
    icon: 'icon-ban'
  },
  {
    href: '/logout',
    title: 'Logout',
    icon: 'icon-logout'
  }
]
