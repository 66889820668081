<template>
  <b-overlay :show="processing" rounded="sm">
    <sidebar-menu :menu="menu" :collapsed="collapsed" :widthCollapsed="sidebarCollapsedWidth" width="270px" :hideToggle="true" />
    <section :class="{'page-content': true,
                      'menu-collapsed': collapsed
    }">
      <!-- toolbar -->
      <div class="container-fluid">
        <b-row class="align-items-center justify-content-between mt-3 mb-5">
          <b-col cols="6">
            <span @click="collapsed = !collapsed" class="text-16 mr-4">
              <i class="fa fa-bars menu-icon" aria-hidden="true"></i>
            </span>
          </b-col>
          <b-col cols="6" class="text-right">
            <span class="text-16 mr-3 d-none d-md-inline-block">{{ user.name }}</span>
            <img src="https://via.placeholder.com/32" alt="user display picture" width="32" height="32" class="shadow-sm curved-edge mr-5 d-none d-md-inline-block">
          </b-col>
        </b-row>
      </div>
      <!-- page content -->
      <slot />
      <p class="text-muted text-center text-14 my-4">&copy; 2020 AirtimeFlip Technology LTD - RC 1621037</p>
    </section>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'
import { SidebarMenu } from 'vue-sidebar-menu'
import '@/assets/css/vue-sidebar-menu.css'
// import 'vue-sidebar-menu/src/scss/vue-sidebar-menu.scss'
import menu from '../data/menu'

export default {
  components: {
    SidebarMenu
  },
  data () {
    return {
      collapsed: false,
      sidebarCollapsedWidth: '50px',
      menu
    }
  },
  computed: {
    ...mapGetters(['processing', 'user'])
  },
  mounted () {
    document.body.classList.add('light')
    this.resizeMenu()
    window.addEventListener('resize', this.resizeMenu)
  },
  methods: {
    resizeMenu () {
      if (window.innerWidth < 500) {
        this.sidebarCollapsedWidth = '0'
        this.collapsed = true
        return
      }
      this.sidebarCollapsedWidth = '50px'
    },
    logout () {
      this.$store.commit('logoutUser')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content {
  margin-left: 280px;
  transition: margin-left 0.3s;

  &.menu-collapsed {
    margin-left: 40px;
  }
}
.custom-toolbar {
  background-color: #F38D04;
  color: white;
}

@media screen and (max-width: 500px) {
  .menu-icon {
    color: #F38D04;
  }
  .page-content {
    margin-left: 0;

    &.menu-collapsed {
      margin-left: 0;
    }
  }
}
</style>
